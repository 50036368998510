<template>
  <div>
    <div style="display: flex;">
        <Button @click="formData.userMemberVos.push({userId})" icon="ios-add-circle-outline" type="primary">新增成员信息</Button>
        <h2 style="margin-bottom:20px;flex:1;padding-left:calc(50% - 190px)">家庭成员信息</h2>
    </div>
    <Table
      border
      :columns="tableColumns"
      :data="formData.userMemberVos"
      max-height="calc(100vh - 200px)"
    ></Table>
  </div>
</template>

<script>
export default {
    props: ['formData',"userId"],
  data() {
    return {
      tableColumns: [
        {
          title: "成员姓名",
          key: "name",
          render: (h, params) => {
            return h("Input", {
              props: {
                value: this.formData.userMemberVos[params.index].name,
              },
              on: {
                "on-change": (e) => {
                  this.formData.userMemberVos[params.index].name = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "成员与案主关系",
          key: "relationship",
           render: (h, params) => {
            return h("Input", {
              props: {
                value: this.formData.userMemberVos[params.index].relationship,
              },
              on: {
                "on-change": (e) => {
                  this.formData.userMemberVos[params.index].relationship = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "成员年龄",
          key: "age",
           render: (h, params) => {
            return h("Input", {
              props: {
                value: this.formData.userMemberVos[params.index].age,
              },
              on: {
                "on-change": (e) => {
                  this.formData.userMemberVos[params.index].age = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "成员工作单位",
          key: "workUnit",
           render: (h, params) => {
            return h("Input", {
              props: {
                value: params.row.workUnit,
              },
              on: {
                "on-change": (e) => {
                  this.formData.userMemberVos[params.index].workUnit = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "成员联系电话",
          key: "phone",
           render: (h, params) => {
            return h("Input", {
              props: {
                value: params.row.mobile,
              },
              on: {
                "on-change": (e) => {
                  this.formData.userMemberVos[params.index].mobile = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "操作",
          width: 70,
          align: "center",
          render: (h,params) => {
            return h('Icon',{
              props: {
                type: 'ios-trash-outline'
              },
              style: {
                textAlign: "center",
                fontSize: '20px'
              },
              on: {
                click: () => {
                  this.formData.userMemberVos.splice(params.index ,1)
                }
              }
            })
          }
        }
      ],
    };
  },
};
</script>
    
<style scoped lang='less'>
</style>